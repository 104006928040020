.tabs {
  color: #1B1B27;
  padding-bottom: 200px;
  @include max-screen($notebook-HD) {
    padding-bottom: 100px;
  }
  @include max-screen($md) {
    max-width: 90%;
    margin: 0 auto;
  }
  &__header {
    display: flex;
    justify-content: center;
    @include max-screen($md){
      flex-wrap: wrap;
    }
  }
  &__title {
    @extend h1;
    width: 820px;
    margin: 0 auto;
    padding-top: 150px;
    text-align: center;
  }

  &__tab {
    display: flex;
    align-items: center;
    cursor: pointer;
    height: 60px;
    color: $white-08;
    border: 1px solid $tab_blue;
    border-radius: 30px;
    padding: 0 40px 0 20px;
    &:hover {
      color: $white;
    }
    &.current {
      color: $white;
      background-color: $tab_blue;
    }
    .material-icons {
      margin-right: 23px;
      @include themify($themes) {
        color: themed('primaryColor');
      }
    }
    &:nth-child(2n) {
      margin: 0 30px;
    }
  }

  &__body {
    position: relative;
    width: 1100px;
    margin: 40px auto;
    height: 1000px;
    @include max-screen($desktop-md){
      height: 1000px;
    }
    @include max-screen($notebook-HD){
      height: 450px;
    }
    @include max-screen($notebook){
      width: 800px;
      height: 700px;
    }
    @include max-screen($md){
      width: 100%;
      height: 700px;
    }
  }
  &__content {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: opacity 0.5s ease-in;
    &.current {
      opacity: 1;
      z-index: 2;
      .tabs__list {
        @include keyframes( tabsList ) {
          0%   { transform: translateY(20px); }
          100%   { transform: translateY(0); }
        }
        @include animation( tabsList 0.5s ease-in );
      }
    }
    @include max-screen($notebook-HD) {
      display: flex;
    }
  }
  &__image {
    width: 100%;
    height: 695px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    @include max-screen($desktop-md){
      height: 550px;
    }
    @include max-screen($notebook){
      height: 500px;
    }
    @include max-screen($md){
      height: 500px;
      background-position: center;
    }
    &--1 {
      background-image: url('../../img/tab-img-1.png');
    }
    &--2 {
      background-image: url('../../img/tab-img-2.png');
    }
    &--3 {
      background-image: url('../../img/tab-img-3.png');
    }
    &--mission {
      &-1 {
        background-image: url('../../img/mission/tab-1.png');
      }
      &-2 {
        background-image: url('../../img/mission/tab-2.png');
      }
      &-3 {
        background-image: url('../../img/mission/tab-3.png');
      }
      &-4 {
        background-image: url('../../img/mission/tab-4.png');
      }
      &-5 {
        background-image: url('../../img/mission/tab-5.png');
      }
    }
    &--hunt{
      &-1 {
        background-image: url('../../img/hunt/tab-1.png');
      }
      &-2 {
        background-image: url('../../img/hunt/tab-2.png');
      }
      &-3 {
        background-image: url('../../img/hunt/tab-3.png');
      }
      &-4 {
        background-image: url('../../img/hunt/tab-4.png');
      }
      &-5 {
        background-image: url('../../img/hunt/tab-5.png');
      }
      &-6 {
        background-image: url('../../img/hunt/tab-6.png');
      }
    }
  }
  &__list {
    width: 680px;
    margin: 0 auto;
    padding-top: 40px;
    @include max-screen($sm){
      max-width: 100%;
    }
    &-item {
      @extend .list__item;
    }
    &-text {
      @extend .subtitle;
      color: $white-075;
    }
  }

  &__projects {
    color: $white;
    background: linear-gradient(180deg, #14141B 0%, rgba(20, 20, 27, 0) 100%);
    background-size: 100% 90%;
    background-repeat: no-repeat;
    padding-bottom: 0;
    @include max-screen($md){
      max-width: 100%;
    }
    .tabs {
      &__header {
        @include screen(0, $macbook, landscape) {
          flex-wrap: wrap;
        }
      }
      &__tab {
        padding: 0 30px 0 20px;
        color: $white-06;
        &:nth-child(2n) {
          margin: 0 20px;
        }
        &.current {
          color: $white;
        }
        @include max-screen($macbook){
          margin: 15px 10px 0 !important;
        }
        @include max-screen($md){
          margin: 0 7px 15px !important;
        }
        @include max-screen($sm){
          padding: 0 20px 0 15px;
          font-size: 16px;
          .material-icons {
            margin-right: 15px;
          }
          &[data-tab = tab-1] {
            order: -2;
          }
          &[data-tab = tab-5] {
            order: -1;
          }
        }
      }
      &__content {
        @include max-screen($notebook-HD) {
          display: block;
        }
        @include max-screen($sm) {
          display: flex;
          flex-direction: column;
        }
      }
      &__title {
        @include max-screen($md){
          max-width: 100%;
          padding-top: 100px;
          padding-bottom: 20px;
        }
        @include max-screen($sm){
          padding: 50px 25px 30px;
        }
      }
      &__description {
        @extend .subtitle;
        color: $white-06;
        max-width: 700px;
        margin: 0 auto;
        padding: 55px 0;
        @include max-screen($desktop-md) {
          padding: 40px 0;
        }
        @include max-screen($sm) {
          order: 1;
          padding: 25px;
        }
      }
      &__body {
        width: auto;
        max-width: 1280px;
        margin: 0 auto;
        @include max-screen($desktop-md) {
          max-width: 1100px;
        }
        @include max-screen($notebook-HD) {
          height: 650px;
        }
        @include max-screen($notebook) {
          max-width: 900px;
        }
        @include max-screen($md) {
          max-width: 90%;
        }
        @include max-screen($sm) {
          max-width: 100%;
          height: 550px;
        }
      }
      &__image {
        box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.25);
        border-radius: 6px;
        background-position: center;
        height: 800px;
        @include max-screen($desktop-md) {
          height: 695px;
        }
        @include max-screen($notebook-HD) {
          height: 500px;
        }
        @include max-screen($notebook) {
          background-position: top;
          height: 500px;
        }
        @include max-screen($sm) {
          height: 400px;
          background-size: cover;
          background-position: calc(100% - 40px) center;
        }
      }
    }
  }
  &__index {
    @include max-screen($desktop-md) {
      padding-bottom: 0;
    }
    @include max-screen($md) {
      max-width: 100%;
      padding: 0 20px;
    }
    @include max-screen($sm) {
      padding: 0;
    }
    .tabs {
      &__header {
        @include max-screen($sm){
          display: none;
        }
      }
      &__tab {
        margin-bottom: 20px;
        &--body {
          display: none;
          @include max-screen($sm){
            display: flex;
            width: fit-content;
            margin: 0 auto !important;
            background-color: $tab_blue;
          }
        }
      }
      &__body {
        @include max-screen($md) {
          height: 500px;
        }
        @include max-screen($sm) {
          height: auto;
        }
      }
      &__content {
        @include max-screen($sm) {
          position: static;
          flex-direction: column;
          opacity: 1;
          padding-bottom: 100px;
        }
      }
      &__image {
        @include max-screen($md) {
          margin-left: -20px;
          width: calc(100% + 150px);
          background-position: top;
        }
        @include max-screen($sm) {
          height: 430px;
          background-size: cover;
          margin-left: 0;
          width: 100%;
          background-position: center;
        }
      }
      &__list {
        @include max-screen($md) {
          margin-left: -130px;
        }
        @include max-screen($sm) {
          margin-left: 0;
          padding-right: 15px;
        }
      }
    }
  }
  .tech {
    display: flex;
    justify-content: space-between;
    width: 200px;
    height: 50px;
    margin: 40px auto;
    &__icon {
      width: 50px;
      background-repeat: no-repeat;
      background-position: center;
      &--angular {
        background-image: url('../../img/icon-angular.png');
      }
      &--react {
        background-image: url('../../img/icon-react.png');
      }
      &--js {
        background-image: url('../../img/icon-js.png');
      }
    }
  }
 }
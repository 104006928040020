.carousel {
  padding: 100px 100px 0;
  overflow: hidden;
  height: 800px;
  @include max-screen($notebook-HD){
    height: 650px;
  }
  @include max-screen($md){
    padding: 50px 50px 0;
  }
  @include max-screen($sm){
    height: 750px;
    padding: 50px 0;
  }
  &__title {
    @extend h2;
    text-align: center;
    padding-top: 120px;
    @include max-screen($desktop-md) {
      padding-top: 80px;
    }
  }
  &__description {
    @extend .subtitle;
    max-width: 730px;
    color: $white-06;
    text-align: center;
    padding-top: 15px;
    margin: 0 auto;

  }
  &__item {
    position: relative;
    padding: 0 20px;
    transition: all 0.3s ease-in;
    filter: blur(2px);
    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(13, 13, 17, 0.85);
    }
    @include max-screen($sm){
      filter: blur(1px);
      &:after {
        background: rgba(13, 13, 17, 0.35);
      }
    }
    &--active {
      @include min-screen($md){
        filter: none;
        padding-top: 0px !important;
        &:after {
          background: none;
        }
        .carousel {
          &__text {
            height: auto;
            display: block;
          }
        }
      }
    }
  }
  &__image {
    height: 520px;
    width: 100%;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    @include max-screen($notebook-HD){
      height: 400px;
    }
    @include max-screen($sm){
      height: 500px;
    }
    &--mission {
      &-1 {
        background-image: url("../../../img/mission/carousel-1.png");
      }
      &-2 {
        background-image: url("../../../img/mission/carousel-2.png");
      }
      &-3 {
        background-image: url("../../../img/mission/carousel-3.png");
      }
      &-4 {
        background-image: url("../../../img/mission/carousel-4.png");
      }
      &-5 {
        background-image: url("../../../img/mission/carousel-5.png");
      }
    }
    &--hunt {
      &-1 {
        background-image: url("../../../img/hunt/carousel-1.jpg");
      }
      &-2 {
        background-image: url("../../../img/hunt/carousel-2.jpg");
      }
      &-3 {
        background-image: url("../../../img/hunt/carousel-3.jpg");
      }
      &-4 {
        background-image: url("../../../img/hunt/carousel-4.jpg");
      }
      &-5 {
        background-image: url("../../../img/hunt/carousel-5.jpg");
      }
      &-6 {
        background-image: url("../../../img/hunt/carousel-6.jpg");
      }
      &-7 {
        background-image: url("../../../img/hunt/carousel-7.jpg");
      }
    }
    &--snow {
      &-1 {
        background-image: url("../../../img/snow/carousel-1.jpg");
      }
      &-2 {
        background-image: url("../../../img/snow/carousel-2.jpg");
      }
      &-3 {
        background-image: url("../../../img/snow/carousel-3.jpg");
      }
      &-4 {
        background-image: url("../../../img/snow/carousel-4.jpg");
      }
      &-5 {
        background-image: url("../../../img/snow/carousel-5.jpg");
      }
      &-6 {
        background-image: url("../../../img/snow/carousel-6.jpg");
      }
      &-7 {
        background-image: url("../../../img/snow/carousel-7.jpg");
      }
    }
    &--jagttider {
      &-1 {
        background-image: url("../../../img/jagttider/carousel-1.jpg");
      }
      &-2 {
        background-image: url("../../../img/jagttider/carousel-2.jpg");
      }
      &-3 {
        background-image: url("../../../img/jagttider/carousel-3.jpg");
      }
      &-4 {
        background-image: url("../../../img/jagttider/carousel-4.jpg");
      }
      &-5 {
        background-image: url("../../../img/jagttider/carousel-5.jpg");
      }
      &-6 {
        background-image: url("../../../img/jagttider/carousel-6.jpg");
      }
    }
    &--trophy {
      &-1 {
        background-image: url("../../../img/trophy/carousel-1.png");
      }
      &-2 {
        background-image: url("../../../img/trophy/carousel-2.png");
      }
      &-3 {
        background-image: url("../../../img/trophy/carousel-3.png");
      }
      &-4 {
        background-image: url("../../../img/trophy/carousel-4.png");
      }
      &-5 {
        background-image: url("../../../img/trophy/carousel-5.png");
      }
    }
    &--fsn {
      &-1 {
        background-image: url("../../../img/fsn/carousel-1.png");
      }
      &-2 {
        background-image: url("../../../img/fsn/carousel-2.png");
      }
      &-3 {
        background-image: url("../../../img/fsn/carousel-3.png");
      }
      &-4 {
        background-image: url("../../../img/fsn/carousel-4.png");
      }
      &-5 {
        background-image: url("../../../img/fsn/carousel-5.png");
      }
    }
  }
  &__text {
    @extend .subtitle;
    height: 0;
    color: $white-06;
    padding-top: 15px;
    overflow: hidden;
    text-align: center;
    @include max-screen($sm){
      width: 90vw;
      margin-left: -30px;
    }
  }
  &__app {
    display: none;
    //display: flex;
    align-items: center;
    height: 100px;
    width: 400px;
    margin-top: 40px;
    margin-left: 60%;
    border: 1px solid $tab_blue;
    padding: 0 30px;
    border-radius: 6px;
    font-size: 24px;
    @include screen(0, $macbook, landscape) {
      margin-left: 0;
      margin-right: 40px;
      float: right;
    }
    @include max-screen($md){
      margin-left: 0;
      margin-right: 40px;
      float: right;
    }
    @include max-screen($sm){
      width: 90%;
      margin: 0 auto;
      float: none;
      padding: 0 15px;
    }
    &-text {
      flex-grow: 1;
    }
    &-link {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 55px;
      height: 55px;
      margin-left: 20px;
      border-radius: 50%;
      background-color: $tab_blue;
      text-decoration: none;
      @include themify($themes) {
        color: themed('primaryColor');
      }
      @include max-screen($sm){
        &:first-of-type {
          margin-left: 0;
        }
      }
    }
    &-icon {
      &.fa-apple {
        margin-top: -5px;
      }
    }

    &--fixed {
      display: flex;
      position: fixed;
      right: 40px;
      bottom: 30px;
      z-index: 5;
      background-color: $body-bg;
      width: 220px;
      height: 60px;
      padding: 0 15px;
      @include max-screen($notebook){
        margin-right: 0px;
        right: 40px;
      }
      @include max-screen($macbook){
        margin-right: 0px;
        right: 20px;
      }
      @include max-screen($ipad-portrait){
        right: 40px;
      }
      @include max-screen($sm){
        right: 15px;
      }

      .carousel__app {
        &-text {
          font-size: 16px;
        }
        &-link {
          width: 40px;
          height: 40px;
          margin-left: 10px;
        }
        &-icon {
          font-size: 20px;
        }
      }
    }
  }

  &-section {
    padding-bottom: 180px;
    @include max-screen($sm){
      padding-bottom: 80px;
    }
  }

  .slick-slide {
    outline: none;
    @include min-screen($md) {
      &:nth-of-type(3n +1) {
        .carousel__item {
          padding-top: 20px;
        }
      }
      &:nth-of-type(3n + 2) {
        .carousel__item {
          padding-top: 100px;
        }
      }
      &:nth-of-type(3n + 3) {
        .carousel__item {
          padding-top: 70px;
        }
      }
    }
    &.slick-current {
      .carousel {
        &__item {
          @include max-screen($md) {
            filter: none;
            padding: 0px;
            &:after {
              background: none;
            }
          }
          @include max-screen($md) {
            padding-top: 50px;
          }
        }
        &__text {
          @include max-screen($md) {
            height: auto;
            display: block;
          }
        }
      }
    }
  }
}
.bottom-form {
  height: 400px;
  padding: 100px 0;
  @include themify($themes) {
    background-color: themed('primaryColor');
  }
  @include max-screen($md){
    height: auto;
    padding: 100px 40px;
  }
  @include max-screen($sm){
    padding: 60px 40px;
    text-align: center;
  }
  &__container {
    width: fit-content;
    max-width: 1325px;
    margin: 0 auto;
    @include max-screen($md){
      max-width: 100%;
    }
  }

  .form {
    height: auto;
    padding: 50px 0 0;
    position: relative;
    @include max-screen($md){
      flex-direction: column;
    }
    &__input {
      margin-right: 15px;
      @include themify($themes) {
        background-color: themed('primaryLight');
      }
      @include max-screen($md){
        max-width: 100%;
      }
    }
    &__button {
      display: block;
      background-color: $tab_blue;
    }
    &__item {
      width: auto;
      margin-bottom: 0;
      @include max-screen($md){
        width: 500px;
        margin-right: 0;
        margin-bottom: 20px;
      }
      @include max-screen($sm){
        width: 100%;
      }
    }
  }
  .subtitle {
    color: $white-075;
  }
}
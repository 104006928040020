$themes: (
  orange: (
    primaryColor: $orange,
    primaryDark: $dark-orange,
    primaryLight: $light-orange,
    appCenterActive: $white,
    appCenterActiveHover: $white-08,
    formButtonColor: $tab_blue,
    formButtonHoverColor: $bg_blue,
    formInputBg: $light-orange
),
  blue: (
    primaryColor: $blue,
    primaryDark: $dark-blue,
    primaryDarkLight: $light-dark-blue,
    primaryLight: $light-blue,
    appCenterActive: $white,
    appCenterActiveHover: $white-08,
    formButtonColor: $tab_blue,
    formButtonHoverColor: $bg_blue,
    animationColor: $blue-gardient,
    formInputBg: $light-blue
  ),
  yellow: (
    primaryColor: $yellow,
    primaryDark: $dark-yellow,
    primaryDarkLight: $yellow,
    primaryLight: $light-yellow,
    appCenterActive: $white,
    appCenterActiveHover: $white-08,
    formButtonColor: $tab_blue,
    formButtonHoverColor: $bg_blue,
    animationColor: $yellow-gardient,
    formInputBg: $dark-yellow
  ),
  azure: (
    primaryColor: $azure,
    primaryDark: $blue,
    primaryDarkLight: $blue,
    primaryLight: $blue,
    appCenterActive: $white,
    appCenterActiveHover: $white-08,
    formButtonColor: $azure,
    formButtonHoverColor: $dark-blue,
    animationColor: $blue-gardient,
    formInputBg: $light-dark-blue
  ),
  green: (
    primaryColor: $green,
    primaryDark: $green-dark,
    primaryDarkLight: $fsn-blue,
    primaryLight: $fsn-blue,
    appCenterActive: $green-dark,
    appCenterActiveHover: $white-08,
    formButtonColor: $green-dark,
    formButtonHoverColor: $green-dark,
    animationColor: $blue-gardient,
    formInputBg: $green-dark
  )
);

@mixin themify($themes) {
  @each $theme, $map in $themes {
    .theme-#{$theme} & {
      $theme-map: () !global;
      @each $key, $submap in $map {
        $value: map-get(map-get($themes, $theme), '#{$key}');
        $theme-map: map-merge($theme-map, ($key: $value)) !global;
      }
      @content;
      $theme-map: null !global;
    }
  }
}

@function themed($key) {
  @return map-get($theme-map, $key);
}
@import "libs/bootstrap-custom";
@import "libs/media-queries";
@import "libs/animate.scss";

@import "../../node_modules/slick-carousel/slick/slick.scss";
@import "../../node_modules/slick-carousel/slick/slick-theme.scss";

@import "font";
@import "variable";
@import "mixins";
@import "themes/themes";

// template import
@import "blocks/header";
@import "blocks/app-center";
@import "blocks/form";
@import "blocks/footer";
@import "blocks/slider";

// products blocks
@import "blocks/products/carousel";
@import "blocks/products/demo";
@import "blocks/products/features";
@import "blocks/products/bottom-form";


//  index page
@import "pages/index/intro";
@import "pages/index/main";
@import "blocks/tabs";
@import "pages/index/history";
@import "pages/index/company";

// partnership page
@import "pages/partnership/first-screen";
@import "pages/partnership/card";
@import "pages/partnership/text";

// products pages
@import "pages/products/products";

* {
  box-sizing: border-box;
}

html.no-scroll {
  body {
    padding-right: 17px;
  }
  .header {
    padding-right: 57px;
  }
  .app-center {
    position: absolute;
  }
}

body.no-scroll {
  padding-right: 17px;
  @include max-screen($sm) {
    padding-right: 0;
  }
}

body {
  font-family: $Roboto-Bold;
  font-size: 16px;
  background-color: $body-bg;
  color: $white;
  width: 100%;
  height: 100%;
}

section, footer {
  max-width: 100vw;
  overflow: hidden;
}
//  TODO: delete if unuse
.container {
  position: absolute;
  width: calc(100vw + 17px);
  overflow-x: hidden;
  padding: 0;
  margin: 0;
  &--scroll {
    height: 100%;
    overflow-y: scroll;
  }
  &:before, &:after {
    display: none;
  }
}

h1 {
  font-family: $UniSans;
  font-size: 64px;
  margin: auto;
  @include max-screen($notebook-HD){
    font-size: 36px;
  }
  @include max-screen($sm){
    font-size: 36px;
  }
}
h2 {
  font-family: $UniSans;
  font-size: 48px;
  margin: auto;
  @include max-screen($sm){
    font-size: 36px;
  }
}
h3 {
  font-family: $UniSans;
  font-size: 36px;
  margin: auto;
}

.subtitle {
  font-family: $Courier;
  font-size: 18px;
  @include screen(0, $macbook, landscape) {
    font-size: 16px;
  }
  @include max-screen($notebook-HD) {
    font-size: 16px;
  }
  @include max-screen($notebook) {
    font-size: 14px;
  }
  @include max-screen($ipad-portrait) {
    font-size: 18px;
  }
}

.button {
  height: 50px;
  border-radius: 4px;
  border: none;
  padding: 0 40px;
  outline: none;
  &__to-app {
    @include themify($themes){
      background-color: themed('primaryColor');
      &:hover {
        background-color: themed('primaryDark');
      }
    }
  }
}

.no-scroll {
  overflow: hidden;
}

.list {
  padding-left: 20px;
  &__title {
    @extend h3;
  }
  &__item {
    list-style-type: square;
    color: $orange;
    line-height: 1.5;
    padding-top: 20px;
  }
  &__text {
    @extend .subtitle;
    color: $white;
  }
}

#trophy, #fsn {
  .tabs {display: none;}
  .features {display: none;}
}
.theme-green {
  .form__button:hover {
    color: $black;
  }
}
.features {
  padding-top: 50px;
  @include max-screen($sm) {
    padding-bottom: 100px;
  }
  &__subtitle {
    @extend .subtitle;
    color: $white-06;
    text-align: center;
    max-width: 720px;
    margin: 0 auto;
    padding: 15px 0 100px;
  }
  &__block {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    max-height: 1500px;
    max-width: 1340px;
    margin: 0 auto;
    @include screen(0, $macbook, landscape) {
      max-width: 100%;
      max-height: 1700px;
    }
    @include max-screen($md){
      max-width: 100%;
      max-height: 2000px;
      padding: 0 10px 50px;
    }
    @include max-screen($sm){
      display: block;
      overflow: hidden;
    }
    &--expanded {
      max-height: 100%!important;
    }
  }
  &__item {
    width: calc(33% - 50px);
    margin: 25px;
    padding: 40px 50px;
    background-color: $bg_blue;
    border-radius: 4px;
    @include max-screen($md){
      width: calc(50% - 20px);
      margin: 10px;
    }
    @include max-screen($sm){
      width: 100%;
      margin: 0 0 20px;
    }
  }
  &__icon {
    width: 70px;
    height: 70px;
    line-height: 70px !important;
    text-align: center;
    border-radius: 50%;
    background-color: $light-dark-blue;

    color: transparent;
    background-position: center;
    background-size: 70%;
    background-repeat: no-repeat;
    @include themify($themes) {
      color: themed('primaryColor');
    }

    // hunt

    &--GEOMap {
      background-image: url("../../../img/hunt/icons/GEOMap.png");
    }
    &--GEOTools {
      background-image: url("../../../img/hunt/icons/GEOTools.png");
    }
    &--GEOAlarm {
      background-image: url("../../../img/hunt/icons/GEOAlarm.png");
    }
    &--GEOTeam{
      background-image: url("../../../img/hunt/icons/GEOTeam.png");
    }
    &--GEOArea{
      background-image: url("../../../img/hunt/icons/GEOArea.png");
    }
    &--GEOFence{
      background-image: url("../../../img/hunt/icons/GEOFence.png");
    }
    &--GEOGrid{
      background-image: url("../../../img/hunt/icons/GEOGrid.png");
    }
    &--GEOPin{
      background-image: url("../../../img/hunt/icons/GEOPin.png");
    }
    &--GEOReport{
      background-image: url("../../../img/hunt/icons/GEOReport.png");
    }
    &--GEOTech{
      background-image: url("../../../img/hunt/icons/GEOTech.png");
    }
    &--GEONature{
      background-image: url("../../../img/hunt/icons/GEONature.png");
    }
    &--GEOAugmented{
      background-image: url("../../../img/hunt/icons/GEOAugmented.png");
    }
    &--GEOTracks{
      background-image: url("../../../img/hunt/icons/GEOTracks.png");
    }
    &--GEOCams{
      background-image: url("../../../img/hunt/icons/GEOCams.png");
    }
    &--GEOCompass{
      background-image: url("../../../img/hunt/icons/GEOCompass.png");
    }
    &--GEOLine{
      background-image: url("../../../img/hunt/icons/GEOLine.png");
    }
    &--GEOSpot{
      background-image: url("../../../img/hunt/icons/GEOSpot.png");
    }
    &--GEOReplay{
      background-image: url("../../../img/hunt/icons/GEOReplay.png");
    }
    &--GEOMode{
      background-image: url("../../../img/hunt/icons/GEOMode.png");
    }
    &--GEOJournal{
      background-image: url("../../../img/hunt/icons/GEOJournal.png");
    }
    &--GEOTracker{
      background-image: url("../../../img/hunt/icons/GEOTracker.png");
    }
    &--GEOLive{
      background-image: url("../../../img/hunt/icons/GEOLive.png");
    }
    &--GEOOptimal{
      background-image: url("../../../img/hunt/icons/GEOOptimal.png");
    }
    &--GEORules{
      background-image: url("../../../img/hunt/icons/GEORules.png");
    }
    &--GEOOfficial{
      background-image: url("../../../img/hunt/icons/GEOOfficial.png");
    }
    &--GEODataTools{
      background-image: url("../../../img/hunt/icons/GEODataTools.png");
    }

  //  mission

    &--secure_login{
      background-image: url("../../../img/mission/icons/secure_login.png");
    }
    &--invite{
      background-image: url("../../../img/mission/icons/invite.png");
    }
    &--agency{
      background-image: url("../../../img/mission/icons/agency.png");
    }
    &--mission_control{
      background-image: url("../../../img/mission/icons/mission_control.png");
    }
    &--join_leave{
      background-image: url("../../../img/mission/icons/join_leave.png");
    }
    &--start_stop{
      background-image: url("../../../img/mission/icons/start_stop.png");
    }
    &--chat{
      background-image: url("../../../img/mission/icons/chat.png");
    }
    &--notifications{
      background-image: url("../../../img/mission/icons/notifications.png");
    }
    &--stream{
      background-image: url("../../../img/mission/icons/stream.png");
    }
    &--photos{
      background-image: url("../../../img/mission/icons/photos.png");
    }
    &--map{
      background-image: url("../../../img/mission/icons/map.png");
    }
    &--pins{
      background-image: url("../../../img/mission/icons/pins.png");
    }
    &--lines{
      background-image: url("../../../img/mission/icons/lines.png");
    }
    &--areas{
      background-image: url("../../../img/mission/icons/areas.png");
    }
  }
  &__title {
    font-size: 24px;
    padding: 20px 0;
  }
  &__text {
    @extend .subtitle;
    color: $white-06;
    font-size: 14px!important;
  }
  &__expand {
    display: none;
    text-decoration: underline;
    width: 100%;
    text-align: center;
    margin-top: 20px;
    height: 50px;
    border: 0;
    background: transparent;
    outline: none;
    @include themify($themes) {
      color: themed('primaryColor');
    }
    @include max-screen($sm){
      display: block;
    }
    &--more {display: block;}
    &--less {display: none;}
    &--open {
      .features__expand {
        &--more {display: none;}
        &--less {display: block;}
      }
    }
  }


  .title {
    text-align: center;
  }

}

#mission {
  .features {
    &__block {
      max-height: 1800px;
      @include max-screen($sm){
        max-height: 1060px;
      }
    }
    &__icon {
      color: transparent;
    }
  }
}
#hunt {
  .features {
    &__block {
      max-height: 2850px;
      @include max-screen($sm){
        max-height: 875px;
      }
    }
    &__icon {
      color: transparent;
    }
  }
}
#snow {
  .features__block {
    max-height: 1800px;
  }
}
.text {
  padding: 150px 250px;
  background-image: url("../../../img/partnership/partnership-first-bg.jpg");
  background-repeat: no-repeat;
  //background-attachment: fixed;
  @include max-screen($md) {
    padding: 150px 40px 50px;
  }
  &__item {}
  &__title {
    @extend .list__title;
  }
  &__paragraph {
    @extend .list__text;
  }
  &__link {
    color: $orange;
    text-decoration: none;
    border-bottom: 1px dashed $orange;
    &:hover {
      border-bottom: none;
    }
  }
  &__ul {
    @extend .list;
  }
  &__li {
    @extend .list__item;
    padding-top: 0 !important;
  }

  .title {
    padding-bottom: 50px;
  }
}
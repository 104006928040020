.slider {
  width: 100%;
  padding-top: 200px;
  @include max-screen($desktop-md){
    padding-top: 40px;
  }
  &__slide {
    position: relative;
    height: 1000px;
    max-height: 65vh;
    //max-height: 80vh;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    background-color: $bg_blue;
    outline: none;
    @include max-screen($desktop-md){
      //max-height: 75vh;
    }
    @include max-screen($notebook-HD){
      max-height: 70vh;
    }
    @include max-screen($md){
      height: 500px;
    }
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      width: 100%;
      height: 200px;
      background: linear-gradient(180deg, #0D0D11 0%, rgba(13, 13, 17, 0) 100%);
    }
    &:after {
      @extend .slider__slide:before;
      top: auto;
      bottom: 0;
      transform: rotate(180deg);
    }
    &--mission_1 {
      background-image: url("../../img/mission/slide-1.png");
    }
    &--mission_2 {
      background-image: url("../../img/mission/slide-2.png");
    }
    &--mission_3 {
      background-image: url("../../img/mission/slide-3.png");
    }
    &--hunt_1 {
      background-image: url("../../img/hunt/slide-1.png");
    }
    &--hunt_2 {
      background-image: url("../../img/hunt/slide-2.png");
    }
    &--hunt_3 {
      background-image: url("../../img/hunt/slide-3.png");
    }
    &--hunt_4 {
      background-image: url("../../img/hunt/slide-4.png");
    }
    &--snow_1 {
      background-image: url("../../img/snow/slide-1.png");
    }
    &--gameday_1 {
      background-image: url("../../img/gameday/slide-1.png");
    }
    &--gameday_2 {
      background-image: url("../../img/gameday/slide-2.png");
    }
    &--trophy_1 {
      background-image: url("../../img/trophy/slide-1.png");
    }
    &--trophy_2 {
      background-image: url("../../img/trophy/slide-2.png");
    }
    &--fsn_1 {
      background-image: url("../../img/fsn/slide-1.png");
    }
    &--fsn_2 {
      background-image: url("../../img/fsn/slide-2.png");
    }
  }
  &__content {
    position: absolute;
    left: 50%;
    transform: translate(-50%);
    width: 900px;
    max-width: 90%;
    padding-top: 100px;
    margin: 0 auto;
    text-align: center;
    z-index: 2;
    @include max-screen($desktop-md){
      padding-top: 20px;
    }
    @include max-screen($sm){
      max-width: 100%;
      padding: 100px 25px 50px;
    }
    &-button {
      @extend .slider-form__button;
      display: none;
      width: 70%;
      margin-top: 40px;
    }
  }
  &__title {
    @extend h1;
  }
  &__description {
    @extend .subtitle;
    padding-top: 15px;
    width: 770px;
    max-width: 80%;
    margin: 0 auto;
    color: $white-06;
  }

  &-nav {
    display: flex;
    justify-content: center;
    width: 80%;
    max-width: 1900px;
    margin: 0 auto 0;
    @include max-screen($notebook-HD){
      top: -20px;
    }
    @include max-screen($md){
      width: 100%;
    }
    &--2 {
      width: 60%;
      @include max-screen($md){
        width: 80%;
      }
      @include max-screen($sm){
        width: 100%;
      }
    }
    &__item {
      position: relative;
      height: 122px;
      background: $bg_blue;
      border-radius: 6px;
      padding: 10px 20px 10px 0;
      overflow: hidden;
      @include screen(0, $macbook, landscape) {
        height: 80px;
      }
      @include max-screen($md){
        height: 80px;
      }
      .icon {
        font-size: 36px;
        min-width: 70px;
        text-align: center;
        @include themify($themes) {
          color: themed('primaryColor');
        }
      }
    }
    .slick {
      &-slide {
        padding: 0 20px;
        outline: none;
      }
      &-current {
        .slider-nav__item {
          background: $tab_blue;
        }
        .progress {
          display: block;
          @include max-screen($sm){
            display: none;
          }
        }
      }
    }
    &__content {
      position: relative;
      display: flex;
      align-items: center;
      height: 100%;
    }
    &__title {
      font-size: 24px;
      padding-bottom: 10px;
      @include max-screen($notebook-HD){
        font-size: 20px;
      }
      @include max-screen($notebook){
        font-size: 16px;
      }
      @include screen(0, $macbook, landscape) {
        font-size: 16px;
        padding-bottom: 0;
      }
      @include max-screen($md){
        font-size: 16px;
        padding-bottom: 0;
      }
    }
    &__description {
      @extend .subtitle;
      color: $white-06;
      @include max-screen($desktop-md){
        font-size: 14px!important;
      }
      @include screen(0, $macbook, landscape) {
        display: none;
      }
      @include max-screen($md){
        display: none;
      }
    }
  }
  &-section {
    position: relative;
    width: 100%;
    min-height: 100vh;
    padding-top: $header-height-md;
    @include max-screen($md){
      padding-top: 0;
    }
  }
  &-projects {
    padding-bottom: 150px;
    @include max-screen($md){
      padding-top: 100px;
      padding-bottom: 50px;
    }
    @include max-screen($desktop-md){
      padding-bottom: 100px;
    }
    @include max-screen($sm){
      padding-top: 0px;
    }
    .slider {
      @include max-screen($sm) {
        padding-top: 400px;
        &__slide {
          background-size: cover;
          &:before {
            height: 50px;
          }
        }
        &-nav {
          &__item {
            background-color: $tab_blue;
          }
          .slick {
            &-track {
              display: flex;
              flex-direction: column;
              width: 100%!important;
            }
            &-list {
              width: 100%!important;
            }
            &-slide {
              width: 100%!important;
              margin-bottom: 20px;
            }
          }
        }
      }
    }
  }

  &-form {
    display: none;
    position: absolute;
    left: 50%;
    transform: translate(-50%);
    bottom: 310px;
    //display: flex;
    width: 80%;
    max-width: 1400px;
    padding: 38px 40px;
    background-color: $bg_blue;
    border-radius: 6px;
    @include max-screen($desktop-md){
      padding: 20px 40px;
      bottom: 250px;
    }
    @include max-screen($notebook-HD){
      padding: 20px;
    }
    @include max-screen($md){
      width: 90%;
      padding: 20px;
      bottom: 200px;
    }
    @include max-screen($sm){
      display: none;
    }
    &__input {
      @extend .form__input;
      width: auto;
      margin-bottom: 0;
      &::placeholder {
        color: $white-04;
      }
      @include max-screen($md){
        max-width: 100%;
      }
    }
    &__button {
      @extend .form__button;
      @include screen(0, $macbook, landscape) {
        font-size: 16px;
      }
      @include max-screen($md){
        min-width: 160px;
        padding: 0 20px !important;
        font-size: 16px;
      }
    }
    &__item {
      width: 100%;
      @include max-screen($md){
        height: 40px !important;
      }
    }
    .form {
      &__item {
        width: 100%;
        padding-right: 15px;
        &:nth-of-type(4) {
          padding-right: 0;
        }
      }
    }
  }
}

// animation
$animationIndent: 200px;

.progress {
  display: none;
  position: absolute;
  left: -$animationIndent;
  top: 0;
  height: 100%;
  width: 200px;
  @include themify($themes) {
    background: themed('animationColor');
    border-right: 1px solid themed('primaryColor');
    &:before {
      content: '';
      position: absolute;
      right: -5px;
      width: 0;
      height: 0;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 4px solid themed('primaryColor');
    }
    &:after {
      @extend .progress:before;
      bottom: 0;
      border-top: 0;
      border-bottom: 4px solid themed('primaryColor');
    }
  }
  animation: navAnimation linear 4.8s;
}

@keyframes navAnimation{
  0% {
    left: -$animationIndent;
  }
  100% {
    left: calc(100% - #{$animationIndent});
  }
}


#mission, #hunt {
  .slider-nav__description {
    @include max-screen($notebook-HD){
      font-size: 12px!important;
    }
  }
}
@font-face {
  font-family: "Roboto-Regular";
  src: url("../fonts/Roboto/Roboto-Regular.ttf");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Roboto-Bold";
  src: url("../fonts/Roboto/Roboto-Bold.ttf");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "UniSans";
  src: url("../fonts/UniSans/UniSans-HeavyCAPS.otf");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "Courier-Regular";
  src: url("../fonts/Courier/Courier-Regular.ttf");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Roboto-Condensed";
  src: url("../fonts/Roboto_Condensed/RobotoCondensed-Regular.ttf");
  font-weight: 400;
  font-style: normal;
}
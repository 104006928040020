.demo {
  display: none;
  height: 100vh;
  width: 100%;
  background-image: url("../../../img/mission/demo-bg.png");
  background-size: contain;
  padding-top: 50px;
  @include screen(0, $macbook, landscape) {
    background-position: center;
    background-repeat: no-repeat;
    background-size: 125%;
  }
  @include max-screen($md){
    height: 50vh;
    background-repeat: no-repeat;
    background-size: cover;
  }
  &__title {
    @extend h2;
    text-align: center;
  }
}
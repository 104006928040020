.cards {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 1300px;
  max-width: 100%;
  margin: 0 auto;
  padding-bottom: 150px;
  padding-top: 10px;
  @include max-screen($desktop-md) {
    width: 1000px;
    padding-bottom: 130px;
  }
  @include screen(0, $macbook, landscape) {
    padding: 50px;
  }
  @include max-screen($md){
    width: 100%;
    padding: 50px 30px;
  }
  @include max-screen($sm){
    flex-direction: column;
    padding: 50px 25px;
  }
  &__item {
    width: calc(50% - 100px);
    @include max-screen($desktop-md) {
      width: calc(50% - 60px);
    }
    @include max-screen($md) {
      width: calc(50% - 20px);
    }
    @include max-screen($sm){
      width: 100%;
    }
    &:nth-of-type(4) {
      .list__title {
        padding-top: 48px;
        @include max-screen($md){
          padding-top: 0;
        }
      }
    }
  }
  .list {
    margin: 0;
    padding-bottom: 120px;
    @include max-screen($desktop-md) {
      padding-bottom: 100px;
    }
    @include max-screen($sm){
      padding-bottom: 50px;
    }
    &__title {
      padding-bottom: 20px;
    }
  }
}
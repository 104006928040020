.header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  align-items: center;
  height: $header-height-4k;
  padding: 0 40px;
  font-family: $Roboto-Bold;
  z-index: 10;
  @include max-screen($desktop-md) {
    //height: $header-height-md;
  }
  &--open {
    position: fixed;
    padding-right: 57px;
  }

  &__logo {
    display: flex;
    min-width: 167px;
    height: 38px;
    background-size: contain;
    background-repeat: no-repeat;
    @include max-screen($md) {
      width: 120px;
    }
    &-text {
      display: flex;
      align-items: center;
      font-family: $Roboto-Condensed;
      font-size: 36px;
      color: $white;
      text-transform: uppercase;
      padding-left: 10px;
      &:before {
        content: '';
        display: inline-block;
        width: 1px;
        height: 20px;
        background-color: $tab_blue;
        margin-right: 10px;
      }
    }
    &--base {
      background-image: url(../../img/logo.svg);
      .header__logo {
        &-text {
          display: none;
        }
        &-link {
          width: 100%;
          height: 100%;
          img {
            display: none;
          }
        }
      }
    }
    &--products {
      @extend .header__logo--base;
    }
  }

  &__menu {

    &-btn {
      display: none;
      height: 60px;
      width: 60px;
      background: transparent;
      padding: 19px 18px;
      border: 0;
      outline: none;
      &--close {
        .header__menu-line {
          &--1 {
            transform: translate(37px);
            width: 0;
          }
          &--2 {
            transform: rotate(45deg);
          }
          &--3 {
            transform: rotate(-45deg);
          }
          &--4 {
            transform: translate(-15px);
            width: 0;
          }
        }
      }
    }
    &-line {
      height: 3px;
      width: 22px;
      background-color: $white;
      border-radius: 1px;
      transition: all 0.3s linear;

      &--2 {
        margin: 5px 0;
      }
      &--3 {
        margin: -8px 0 5px;
      }
    }
  }

  .menu {
    display: flex;
    flex-grow: 1;
    padding-left: 50px;
    @include max-screen($md) {
      padding-left: 30px;
    }
    &__item {
      position: relative;
      display: flex;
      align-items: center;
      height: $header-height-4k;
      color: $white-075;
      padding: 0 15px;
      @include max-screen($desktop-md) {
        height: $header-height-md;
      }
      &:hover {
        color: $white;
        cursor: pointer;
      }
      &--active {
        color: $white;
      }
      &--dropdown {
        cursor: pointer;
      }
      &--expanded {
        .icon--arrow-down {
          transform: rotate(180deg);
        }
      }
    }
    &__title {
      display: block;
      width: 100%;
      line-height: $header-height-4k;
      color: inherit;
      text-decoration: none;
      font-size: 16px;
      @include max-screen($desktop-md) {
        line-height: $header-height-md;
      }
    }

    &-right {
      display: flex;
      align-items: center;
      &__link {
        display: inline-block;
        margin-right: 35px;
        color: $white;
        text-decoration: none;
        line-height: $header-height-4k;
        @include max-screen($desktop-md) {
          line-height: $header-height-md;
        }
        @include max-screen($sm){
          width: 60%;
          margin: 0 auto;
          border-bottom: 1px solid $body-bg;
        }
      }
      &__button {}
      &__sign_up {
        @extend .button;
        //display: flex;
        display: none;
        align-items: center;
        justify-content: center;
        height: 45px!important;
        width: 170px;
        margin-right: 0;
        @include themify($themes){
          background-color: themed('primaryColor');
          &:hover {
            background-color: themed('primaryDark');
          }
        }
        i {
          display: none;
        }
        @include max-screen($sm){
          margin: 15px auto 0;
        }
      }
      &__launch {
        @extend .menu-right__sign_up;
        display: flex;
        width: 180px;
        //margin-right: 10px;
        height: 45px;
        @include max-screen($sm){
          margin-top: 40px;
        }
      }
      &__app_center {
        display: flex;
        align-items: center;
        width: 155px;
        height: 40px;
        padding: 0 10px;
        border: none;
        border-radius: 4px;
        color: $white;
        cursor: pointer;
        outline: none;
        @include themify($themes){
          background-color: themed('primaryColor');
          @include max-screen($sm){
            background-color: transparent;
          }
          &:hover {
            background-color: themed('primaryDark');
            @include max-screen($sm){
              background-color: transparent;
            }
          }
        }
        @include max-screen($sm){
          width: 45px;
          justify-content: center;
        }
        &--active {
          @include themify($themes){
            color: themed('primaryColor');
            background-color: themed('appCenterActive');
            @include max-screen($sm){
              background-color: transparent;
            }
            &:hover {
              background-color: themed('appCenterActiveHover');
              @include max-screen($sm){
                background-color: transparent;
              }
            }
          }
          .icon__close {
            display: inline-block !important;
          }
          .icon__apps {
            display: none;
          }
        }
        .button__title {
          padding-left: 15px;
          @include max-screen($sm){
            display: none;
          }
        }
        .icon {
          &__apps {
            width: 24px;
            height: 24px;
            background-image: url("../../img/icon_apps.png");
          }
          &__close {
            display: none;
          }
        }
      }
      &__products {
        display: none;
      }
    }
  }

  .submenu {
    position: absolute;
    top: 100%;
    left: 0;
    width: 190px;
    max-height: 0;
    transition: max-height 0.3s ease-in;
    display: block;
    overflow: hidden;
    background-color: $bg_blue;
    @include max-screen($sm){
      width: 100%;
    }
    &--visible {
      max-height: 350px;
    }
    &__item {
      padding: 0 20px;
      height: 50px;
      //@extend .menu__item;
      &:nth-of-type(3) {
        display: none;
      }
      &:nth-of-type(4) {
        display: none;
      }
    }
    &__title {
      @extend .menu__title;
      line-height: 50px;
    }
  }

  &.header__mobile {
    display: flex;
    justify-content: space-between;
    background-position-y: -70px;
    @include max-screen($sm) {
      padding: 0 10px 0 0;
      height: 60px;
    }
    &.header {
      &__mobile--open {
        background-color: $tab_blue;
        .menu {
          max-height: 280px;
          padding: 60px 0 0;
          &--expanded {
            max-height: 700px;
          }
          &__item {
            &--dropdown {
              position: relative;
              height: auto;
              max-height: 70px;
              flex-direction: column;
              overflow: hidden;
              border-top: 1px solid $body-bg;
              transition: max-height 0.3s ease-in;
              .menu__title {
                //line-height: 68px;
              }
            }
            &--expanded {
              max-height: 420px;
            }
          }
        }
        .submenu {
          position: static;
          border-radius: 10px;
        }
        .icon--arrow-down {
          @include max-screen($sm) {
            position: absolute;
            right: 20%;
            top: 15px;
          }
        }
      }
      &--open {
        background-color: $body-bg;
      }
    }
    .header {
      &__menu-btn {
        display: block;
      }
    }
    .menu {
      top: 0;
      z-index: -1;
      max-height: 0;
      overflow: hidden;
      width: 100%;
      background-color: $tab_blue;
      position: absolute;
      display: block;
      left: 0;
      padding: 0;
      text-align: center;
      transition: all 0.3s ease-in;
      &__title {
        width: 60%;
        margin: 0 auto;
        border-bottom: 1px solid $body-bg;
      }
    }
  }
}
// font-family
$Roboto-Regular: "Roboto-Regular", Helvetica, Arial, sans-serif;
$Roboto-Bold: "Roboto-Bold", Helvetica, Arial, sans-serif;
$Courier: "Courier-Regular", Helvetica, Arial, sans-serif;
$UniSans: "UniSans", Helvetica, Arial, sans-serif;
$Roboto-Condensed: "Roboto-Condensed", Helvetica, Arial, sans-serif;

//  color
$white: #ffffff;
$white-08: rgba(255, 255, 255, 0.8);
$white-075: rgba(255, 255, 255, 0.75);
$white-06: rgba(255, 255, 255, 0.6);
$white-04: rgba(255, 255, 255, 0.4);
$gray: #1D1D26;
$red: rgba(218, 62, 22, 0.5);
$black: #000000;

$tab_blue: #1B1B27;
$bg_blue: #14141B;
$body-bg: #0D0D11;
$error: #960113;

// theme colors
$orange: #DA3E16;
$dark-orange: #A92F0F;
$light-orange: #DB5330;

$blue: #007EE5;
$light-blue: #2393EE;
$dark-blue: #0062b3;
$blue-gardient: linear-gradient(270deg, rgba(0, 126, 229, 0.404) 0%, rgba(0, 126, 229, 0) 100%);
$light-dark-blue: #1D1D25;

$yellow: #F2C94C;
$dark-yellow: #dca600;
$light-yellow: #ffd95f;
$yellow-gardient: linear-gradient(270deg, rgba(242, 201, 76, 0.4) 0%, rgba(242, 201, 76, 0) 100%);

$azure: #5997E6;

$green: #5EDFAE;
$green-dark: #04CC9C;
$fsn-blue: #00bffe;

// device width
$nexus5-portrait: 360px;
$iphone5-portrait: 320px;
$iphone6-portrait: 375px;
$iphone6-plus-portrait: 414px;
$iphone8-landscape: 667px;
$ipad-portrait: 768px;
$ipad-landscape: 1024px;
$notebook: 1366px;
$macbook: 1280px;
$notebook-HD: 1600px;

$sm: 767px;
$md: 992px;
$lg: 1200px;
$desktop: 1400px;
$desktop-md: 1930px;

// custom variable
$left-indent-4K: 25%;
$left-indent-desktop-md: 270px;
$left-indent-small: 100px;

$header-height-4k: 70px;
$header-height-md: 50px;
$header-height: 80px;
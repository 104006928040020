@mixin keyframes($animationName) {
  @keyframes #{$animationName} {
    @content;
  }
}

@mixin animation($animate...) {
  $max: length($animate);
  $animations: '';

  @for $i from 1 through $max {
    $animations: #{$animations + nth($animate, $i)};

    @if $i < $max {
      $animations: #{$animations + ", "};
    }
  }
  animation: $animations;
}
.animate {
  position: relative;
  @include keyframes( animateTop ) {
    0%   { transform: translateY(80px); }
    100%   { transform: translateY(0); }
  }
  @include animation( animateTop 0.8s);
}

.animate-top {
  position: relative;
  @include keyframes( animateToTop ) {
    0%   { transform: translateY(150px); }
    100%   { transform: translateY(0); }
  }
  @include animation( animateToTop 1s linear );
}
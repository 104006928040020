.main {
  position: relative;
  background-image: url("../../../img/main-bg.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 0 -100px;
  width: 100%;
  color: $white;
  @include max-screen($md) {
    background-size: 300% 100vh;
  }
  @include screen(0, $macbook, landscape) {
    background-size: 150% 110vh;
    background-position: -200px -100px;
  }
  @include max-screen($sm){
    background-position: 0 -40px;
  }
  &__mobile {}
  &--show {
    @include keyframes( mainContent ) {
      0%   { transform: translateY(60px); }
      100% { transform: translateY(0); }
    }
    .main__content {
      @include animation(mainContent linear 1s);
    }

    .technologies__item {
      @for $i from 0 through 6 {
        &:nth-of-type(#{$i}) {
          $time: calc(#{$i} * 0.2s);
          @include animation(mainContent linear $time );
        //   TODO: add animation delay
        }
      }
    }
  }
  &--hide {}
  &__content {
    padding-top: 21vh;
    margin-left: $left-indent-4K;
    @include max-screen($desktop-md) {
      padding-top: 190px;
      margin-left: $left-indent-desktop-md;
    }
    @include max-screen($notebook-HD) {
      padding-top: 100px;
    }
    @include screen(0, $macbook, landscape) {
      margin-left: $left-indent-small;
    }
    @include max-screen($md) {
      margin-left: $left-indent-small;
    }
    @include max-screen($sm) {
      margin-left: 0;
      padding: 100px 25px ;
    }
    @include screen(0, $sm, landscape) {
      padding-top: 50px;
    }
  }
  &__title {
    @extend h1;
    max-width: 700px;
    margin: 0!important;
    @include max-screen($sm){
      font-size: 42px !important;
    }
  }
  &__description {
    @extend .subtitle;
    max-width: 400px;
    line-height: 1.5;
    margin: 30px 0;
    color: $white-075;
    @include max-screen($notebook-HD) {
      margin: 15px 0 20px;
    }
    @include max-screen($sm) {
      margin: 0;
      padding-top: 150px;
    }
    @include screen(0, $sm, landscape) {
      padding-top: 100px;
      padding-bottom: 10px;
    }
  }
  &__button {
    @extend .button__to-app;
  }
  &-info {
    width: 800px;
    margin: 250px auto 0;
    padding-bottom: 50px;
    text-align: center;
    @include max-screen($notebook-HD) {
      margin-top: 150px;
    }
    @include max-screen($md) {
      max-width: 90%;
    }
    @include max-screen($sm) {
      margin-top: 0px;
      padding: 20px 25px 0;
      max-width: 100%;
      background: linear-gradient(180deg, #14141A 0%, rgba(20, 20, 26, 0) 100%);
    }
    &__title {
      @extend h2;
      padding-bottom: 20px;
    }
    &__description {
      @extend .subtitle;
    }
  }
}

.technologies {
  display: flex;
  flex-wrap: wrap;
  width: 1330px;
  max-width: 80%;
  margin-left: $left-indent-4K;
  padding-top: 315px;
  @include max-screen($desktop-md) {
    padding-top: 120px;
    margin-left: $left-indent-desktop-md;
  }
  @include max-screen($notebook-HD) {
    padding-top: 100px;
    max-width: calc(100% - #{$left-indent-desktop-md});
  }
  @include screen(0, $macbook, landscape) {
    margin-left: $left-indent-small;
    max-width: calc(100% - #{$left-indent-small});
  }
  @include max-screen($md) {
    margin: 0 auto;
    padding-top: calc(55vh - 350px);
    max-width: 90%;
  }
  @include max-screen($sm) {
    flex-direction: column;
    padding-bottom: 50px;
  }
  &__item {
    width: 33.33%;
    padding-right: 105px;
    height: 230px;
    @include max-screen($notebook-HD) {
      padding-top: 10px;
      padding-right: 90px;
    }
    @include screen(0, $macbook, landscape) {
      padding-right: 50px;
    }
    @include max-screen($md) {
      padding-right: 20px;
      height: 300px;
    }
    @include max-screen($sm) {
      width: 100%;
      height: auto;
      padding-bottom: 50px;
    }
  }
  &__icon {
    display: block;
    width: 48px;
    height: 48px;
    background-size: contain;
    @include max-screen($desktop-md) {
      width: 35px;
      height: 35px;
    }
    &--ai {
      background-image: url("../../../img/icon-ai.png");
    }
    &--ar {
      background-image: url("../../../img/icon-ar.png");
    }
    &--ml {
      background-image: url("../../../img/icon-ml.png");
    }
    &--ms {
      background-image: url("../../../img/icon-ms.png");
    }
    &--vr {
      background-image: url("../../../img/icon-vr.png");
    }
    &--w2 {
      background-image: url("../../../img/icon-w2.png");
    }
  }
  &__title {
    font-size: 24px;
    padding: 24px 0 10px;
  }
  &__description {
    @extend .subtitle;
    color: $white-075;

  }

}
.form {
  width: 100%;
  height: 400px;
  display: flex;
  align-items: center;
  padding-left: $left-indent-4K;
  color: $black;
  @include themify($themes){
    background-color: themed('primaryColor');
  }
  @include max-screen($desktop-md){
    padding-left: $left-indent-desktop-md;
  }
  @include screen(0, $macbook, landscape) {
    padding-left: $left-indent-small;
  }
  @include max-screen($md){
    padding-left: $left-indent-small;
  }
  @include max-screen($sm){
    padding: 0 25px;
  }
  &__wrapper {
    width: 910px;
    max-width: 100%;
  }
  &__title {
    @extend h2;
  }
  &__description {
    @extend .subtitle;
    color: $black;
    font-size: 18px !important;
    width: 650px;
    max-width: 100%;
    padding: 50px 0 40px;
  }
  &__form {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: space-between;
    @include max-screen($md){
      padding-right: $left-indent-small / 2;
    }
    @include max-screen($sm){
      flex-direction: column;
      padding: 0;
    }
  }
  &__input {
    @extend .subtitle;
    position: relative;
    width: 680px;
    height: 50px;
    border-radius: 4px;
    border: none;
    color: $black;
    padding: 0 20px;
    margin-bottom: 30px;
    @include themify($themes){
      background-color: themed('formInputBg');
    }
    @include max-screen($md){
      max-width: calc(100% - 200px);
    }
    @include max-screen($sm){
      max-width: 100%;
    }
    &:focus, &:active {
      outline: none;
    }
    &::placeholder {
      color: $black;
    }
    &+ label.error {
      position: absolute;
      display: block;
      left: 0;
      top: 55px;
      color: $tab_blue;
    }
  }
  &__textarea {
    @extend .form__input;
    height: 200px;
    padding: 20px;
  }
  &__button {
    color: $white;
    @include themify($themes){
      background-color: themed('formButtonColor');
      &[disabled] {
        opacity: 0.8;
        &:hover {
          background-color: themed('formButtonColor');
        }
      }
      &:hover {
        background-color: themed('formButtonHoverColor');
      }
    }
  }
  &__message {
    display: none;
    &--success {
      position: absolute;
      bottom: -1.5em;
    }
  }
  &__item {
    position: relative;
    display: inline-block;
  }
  &-partnership {
    height: auto;
    padding-top: 120px;
    padding-bottom: 120px;
    @include max-screen($desktop-md){
      padding-top: 100px;
      padding-bottom: 100px;
    }
    @include max-screen($md){
      padding: 100px 50px;
    }
    @include max-screen($sm){
      padding: 50px 25px;
    }
    .form {
      &__form {
        display: block;
        width: 800px;
        @include max-screen($md){
          max-width: 100%;
        }
      }
      &__input {
        width: 100%;
        @include max-screen($md){
          max-width: 100%;
        }
      }
      &__button {
        @include max-screen($sm){
          width: 100%;
        }
      }
    }
  }
}

#contact_form {
  label {
    position: relative;
    top: -15px;
  }
}
#trial_form {
  justify-content: space-between;
  label {
    top: -18px;
    color: $error;
    font-size: 14px;
    @include max-screen($md) {
      font-size: 10px;
    }
  }
}

#request_form {
  justify-content: space-between;
  label {
    top: -18px;
    color: $error;
    font-size: 14px;
  }
}
.intro {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  color: $white;
  overflow: hidden;
  z-index: 2;
  @include max-screen($md) {
    height: 1024px;
    min-height: 100vh;
 }
  @include max-screen($sm) {
    height: 600px;
 }
  &__mobile {
    position: static;
  }
  &--hide {
    z-index: 1;
    .intro__container {
      opacity: 0;
    }
  }
  &--show {
    //overflow-y: scroll;
    .intro__content {
      @include keyframes( introContent ) {
        0%   { transform: translateY(40px); }
        100%   { transform: translateY(0); }
      }
      @include animation( introContent 1s linear );
    }
  }
  &__container {
    width: 100%;
    height: 100%;
    background-image: url("../../../img/intro-bg_lg.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-color: $body-bg;
    opacity: 1;
    transition: opacity 0.8s ease-in;
    overflow: hidden;
    @include max-screen($md){
      background-image: url("../../../img/intro-bg_sm.jpg");
    }
    @include max-screen($sm){
      background-image: url("../../../img/intro-bg_xs.jpg");
    }
  }
  &__content {
    padding-top: 58vh;
    margin-left: $left-indent-4K;
    max-width: 900px;
    @include max-screen($desktop-md) {
      margin-left: $left-indent-desktop-md;
    }
    @include max-screen($notebook-HD) {
      padding-top: 50vh;
      max-width: 700px;
    }
    @include screen(0, $macbook, landscape) {
      margin-left: $left-indent-small;
    }
    @include max-screen($md) {
      padding-top: 70%;
      margin-left: $left-indent-small;
    }
    @include max-screen($sm) {
      margin-left: 0;
      padding: 200px 25px 40px;
    }
  }
  &__title {
    @extend h1;
  }
  &__description {
    @extend .subtitle;
    max-width: 650px;
    line-height: 1.5;
    margin: 30px 0;
  }
  &__button {
    @extend .button__to-app;
  }
}
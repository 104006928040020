.history {
  height: 1000px;
  color: $white;
  background-color: $bg_blue;
  padding-top: 150px;
  overflow: hidden;
  @include max-screen($notebook-HD) {
    height: 125vh;
  }
  @include max-screen($md) {
    height: auto;
    padding-bottom: 150px;
  }
  @include max-screen($sm) {
    padding: 50px 0;
  }
  &__title {
    @extend h2;
    padding-left: 50%;
    padding-bottom: 60px;
    @include max-screen($md) {
      padding-left: 5vw;
    }
  }

  .slider {
    // slider variables
    $slide-count: 4;
    //$slide-width: 700px;
    $slide-width: 30vw;
    $slide-width-sm: 95vw;
    $last-slide-width: 50vw;
    $width-without-last: $slide-width * ($slide-count - 1);

    position: relative;
    width: calc(#{$slide-width} * #{$slide-count} * 2);
    left: calc(-#{$width-without-last} + 50vw);
    @include max-screen($md) {
      width: calc(#{$slide-width-sm} * #{$slide-count} * 2 - 40vw);
      left: calc(-#{$slide-width-sm} * (#{$slide-count} - 1) + 5vw);
    }
    padding-top: 0;
    &__item {
      width: $slide-width;
      @include max-screen($md) {
        width: $slide-width-sm;
      }
      &:first-of-type {
        .slider__header {
          margin-left: 2px;
          &:after {
            left: -2px;
          }
        }
      }
      &:last-of-type {
        width: $last-slide-width;
        @include max-screen($md) {
          width: $slide-width-sm;
        }
      }
    }
    &__header {
      position: relative;
      border-bottom: 13px solid $orange;
      padding-bottom: 25px;
      color: $orange;
      font-family: $Courier;
      &:after {
        content: '';
        display: block;
        position: absolute;
        bottom: -21px;
        left: 0;
        width: 25px;
        height: 25px;
        background-color: $orange;
        border: 2px solid #F9F9F9;
        border-radius: 50%;
      }
    }
    &__title {
      font-size: 24px;
      padding: 35px 0 20px;
    }
    &__description {
      @extend .subtitle;
      width: $slide-width / 2;
      color: $white-06;
      line-height: 1.5;
      margin: 0;
      @include max-screen($notebook-HD) {
        width: $slide-width / 1.35;
      }
      @include max-screen($md) {
        width: $slide-width-sm / 1.35;
      }
    }
    &__container {
      display: flex;
      cursor: pointer;
      left: $width-without-last;
      width: calc(#{$width-without-last} + #{$last-slide-width});
      @include max-screen($md) {
        left: calc(#{$slide-width-sm} * #{$slide-count - 1}) ;
        width: calc(#{$slide-width-sm} * #{$slide-count});
      }
    }
  }
}
.products {
  body {
    padding-top: 70px;
  }
  .header {
    position: fixed;
    //height: 80px;
    background: $body-bg;
    border-bottom: 1px solid $tab_blue;
    @include screen(0, $macbook, landscape) {
      padding: 0 20px;
      .menu-right__sign_up {
        //width: 120px;
        padding: 0 20px;
      }
    }
    @include max-screen($sm){
      flex-direction: row-reverse;
      padding-left: 20px;
      border-bottom: 0;
    }
    &.header__mobile {
      .header__menu-btn {
        @include max-screen($ipad-portrait) {
          display: none;
        }
        @include max-screen($ipad-portrait) {
          display: block;
        }
      }

      .menu-right {
        @include max-screen($sm){
          display: none;
        }
        &__products {
          display: flex;
          height: 140px;
          flex-direction: column;
        }
      }
      &.header__mobile--open {
        border-bottom: 1px solid $body-bg;
        .menu {
          max-height: 590px;
          padding: 70px 0 0;
          &__item {
            &:first-of-type {
              border-top: 1px solid $body-bg;
            }
          }
          &-right__products--visible-sm {
            @include max-screen($md){
              display: none;
            }
            @include max-screen($sm){
              display: block;
            }
          }
        }
      }
    }
  }

  label.error {
    color: red;
  }
}
.app-center {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  display: block;
  max-height: 0;
  min-height: 0;
  background: $body-bg;
  padding: 0 230px;
  overflow: hidden;
  transition: all ease-in-out 0.5s;
  z-index: 3;
  @include max-screen($notebook-HD) {
    padding: 0 100px;
  }
  @include screen(0, $macbook, landscape) {
    padding: 0 40px;
  }
  @include max-screen($md) {
    padding: 0 40px;
  }
  @include max-screen($sm) {
    padding: 0 10px;
  }
  &--open {
    max-height: 100vh;
    min-height: 100vh;
    @include max-screen($md) {
      height: 100%;
    }
    @include max-screen($sm) {
      overflow-y: scroll;
    }
  }
  &__title {
    @extend h1;
    padding-top: 150px;
    @include max-screen($desktop-md) {
      padding-top: 110px;
    }
    @include max-screen($notebook-HD) {
      padding-top: 80px;
    }
    @include max-screen($notebook) {
      padding-top: 70px;
      font-size: 36px!important;
    }
    @include max-screen($sm) {
      padding-top: 110px;
    }
  }

  .apps {
    display: flex;
    flex-wrap: wrap;
    padding-top: 45px;
    @include max-screen($desktop-md) {
      padding-top: 30px;
    }
    @include max-screen($notebook) {
      padding-top: 10px;
    }
    &__item {
      position: relative;
      width: calc((100% - 60px)/3);
      height: 530px;
      padding-left: calc((100% - 120px)/6);
      padding-right: 25px;
      background-color: $bg_blue;
      background-repeat: no-repeat;
      background-position: left bottom;
      cursor: pointer;
      @include max-screen($desktop-md) {
        height: 340px;
        width: calc((100% - 40px)/3);
        padding-left: calc((100% - 80px)/6);
        padding-right: 15px;
      }
      @include max-screen($notebook-HD) {
        height: 250px;
        padding-left: 200px;
      }
      @include max-screen($notebook) {
        height: 240px;
        padding-left: 145px;
      }
      @include max-screen($md) {
        height: 250px;
        width: calc(50% - 10px);
        margin-bottom: 10px;
      }
      @include max-screen($sm) {
        width: 100%;
        margin: 0 0 20px;
        padding-left: 45%;
      }

      &:nth-of-type(3n + 2) {
        margin: 0 30px 30px;
        @include max-screen($desktop-md) {
          margin: 0 20px 20px;
        }
        @include max-screen($notebook) {
          margin: 0 15px 15px;
        }
        @include max-screen($md) {
          margin: 0;
        }
        @include max-screen($sm) {
          margin: 0 0 20px;
        }
      }
      &:nth-of-type(n + 1) {
        @include max-screen($md) {
          margin-right: 10px;
        }
        @include max-screen($sm) {
          margin-right: 0;
        }
      }
      &--mission {
        background-image: url("../../img/bg-app-mission.png");
        background-size: 45% 100%;
        @include max-screen($notebook-HD) {
          background-size: 40% 100%;
        }
        @include screen(0, $sm, landscape) {
          background-size: 30% 100%;
        }
      }
      &--hunt {
        background-image: url("../../img/bg-app-hunt.png");
        background-size: 45% 80%;
        @include max-screen($md) {
          background-size: 50% 80%;
        }
        @include screen(0, $sm, landscape) {
          background-size: 35% 100%;
        }
      }
      &--snow {
        background-image: url("../../img/bg-app-snow.png");
        background-size: 45% 80%;
        @include screen(0, $sm, landscape) {
          background-size: 35% 100%;
        }
      }
      &--event {
        background-image: url("../../img/bg-app-event.png");
        background-size: 45% 100%;
        @include screen(0, $sm, landscape) {
          background-size: 25% 100%;
        }
        .apps__title {
          @include max-screen($desktop-md) {
            padding-top: 50px;
          }
          @include max-screen($notebook-HD) {
            padding-top: 30px;
            font-size: 30px!important;
          }
          @include screen(0, $macbook, landscape) {
            padding-top: 5px;
          }
          @include max-screen($md) {
            padding-top: 15px;
          }
        }
      }
      &--hunting_times {
        background-image: url("../../img/bg-app-hunting.png");
        background-size: 45% 100%;
        @include max-screen($notebook) {
          background-size: 40% 100%;
        }
        @include screen(0, $sm, landscape) {
          background-size: 30% 100%;
        }
        .apps {
          &__title {
            @include max-screen($desktop-md) {
              padding-top: 50px;
            }
            @include max-screen($notebook-HD) {
              padding-top: 30px;
              font-size: 30px!important;
            }
            @include screen(0, $macbook, landscape) {
              padding-top: 5px;
            }
            @include max-screen($md) {
              padding-top: 15px;
            }
          }
          &__btn--launch {
            visibility: hidden;
          }
        }
      }

      &--trophy {
        background-image: url("../../img/bg-app-trophy.png");
        background-size: 45% 100%;
      }
      &--fsn {
        background-image: url("../../img/bg-app-fsn.png");
        background-size: 45% 100%;
      }
      &-link {
        position: absolute;
        left: 0;
        top: 0;
        display: block;
        overflow: hidden;
        width: 100%;
        height: 100%;
      }
    }
    &__title {
      @extend h2;
      padding-top: 120px;
      @include max-screen($desktop-md) {
        font-size: 36px !important;
        padding-top: 80px;
      }
      @include max-screen($notebook-HD) {
        padding-top: 30px;
      }
      @include screen(0, $macbook, landscape) {
        padding-top: 20px;
      }
      @include max-screen($md) {
        padding-top: 20px;
      }
    }
    &__links {
      position: relative;
      padding: 20px 0;
      @include max-screen($desktop-md) {
        padding: 15px 0 10px;
      }
      @include max-screen($md) {
        padding: 5px 0 10px;
      }
    }
    &__link {
      display: inline-block;
      width: 24px;
      height: 24px;
      background-size: cover;
      margin-right: 5px;
      &--google {
        background-image: url("../../img/icon-google.svg");
      }
      &--apple {
        background-image: url("../../img/icon-apple.svg");
      }
      &--windows {
        background-image: url("../../img/icon-windows.svg");
      }
    }
    &__description {
      @extend .subtitle;
      color: $white-075;
      line-height: 1.5;
      @include max-screen($desktop-md) {
        line-height: 1.2;
        font-size: 14px!important;
      }
    }
    &__buttons {
      position: absolute;
      bottom: 45px;
      display: flex;
      @include max-screen($desktop-md) {
        bottom: 30px;
      }
      @include max-screen($notebook) {
        bottom: 15px;
      }
    }
    &__btn {
      height: 60px;
      background-color: $tab_blue;
      border: none;
      border-radius: 30px;
      outline: none;
      font-size: 26px;
      @include max-screen($desktop-md) {
        height: 40px;
        border-radius: 20px;
        font-size: 18px;
      }
      &:hover {
        color: $white-08;
        background-color: $body-bg;
      }
      &--launch {
        display: block;
        text-align: center;
        line-height: 40px;
        width: 215px;
        margin-right: 15px;
        color: inherit;
        text-decoration: none;
        @include max-screen($desktop-md) {
          width: 140px;
        }
        @include screen(0, $macbook, landscape) {
          width: 90px;
          font-size: 14px;
        }
        @include max-screen($md) {
          width: 120px;
        }
      }
      &--dots {
        display: none;
        //display: flex;
        width: 60px;
        align-items: center;
        justify-content: center;
        @include max-screen($desktop-md) {
          width: 40px;
        }
      }
    }
  }
}
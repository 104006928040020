.partnership-first {
  width: 100%;
  height: 100vh;
  background-image: url("../../../img/partnership/partnership-first-bg.jpg");
  background-size: cover;
  background-position: bottom;
  @include max-screen($md){
    height: 1024px;
    min-height: 100vh;
  }
  @include max-screen($sm){
    height: 100%;
    //height: 1024px;
    min-height: 100vh;
  }
  &__content {
    @extend .animate-top;
    @extend .intro__content;
    padding-top: 20vh;
    @include max-screen($md){
      padding-top: 30%;
    }
  }
  &__title {
    @extend h1;
  }
  &__description {
    @extend .intro__description;
    color: $white-06;
  }
  &__button {
    @extend .button__to-app;
  }
}
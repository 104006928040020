.company {
  position: relative;
  padding: 250px 0;
  @include max-screen($notebook-HD) {
    padding: 150px 0 100px;
  }
  @include max-screen($md) {
    padding: 100px 0;
  }
  @include max-screen($ipad-portrait) {
    padding-bottom: 0;
  }
  &__title {
    @extend h2;
    text-align: center;
    padding-bottom: 30px;
  }
  &__description {
    @extend .subtitle;
    max-width: 680px;
    margin: 0 auto;
    text-align: center;
  }

  .team {
    position: relative;
    width: 1500px;
    max-width: 100%;
    height: 1260px;
    margin: 0 auto;
    background-image: url("../../../img/team.png");
    background-size: cover;
    background-repeat: no-repeat;
    @include max-screen($desktop-md) {
      width: 1300px;
      height: 1000px;
      background-size: contain;
    }
    @include max-screen($notebook-HD) {
      margin-top: 50px;
    }
    @include max-screen($ipad-portrait){
      height: auto;
      min-height: 800px;
      overflow: -webkit-paged-x;
      padding-bottom: 50px;
    }
    @include max-screen($sm) {
      min-height: 1500px;
      width: 100%;
      background-image: url("../../../img/team_mobile.png");
    }
    &__button {
      display: none;
      position: relative;
      top: 750px;
      left: 50%;
      transform: translate(-50%);
      align-items: center;
      cursor: pointer;
      height: 60px;
      width: 240px;
      color: $white-08;
      background-color: $tab_blue;
      border: 1px solid $tab_blue;
      border-radius: 30px;
      padding: 0 40px 0 20px;
      outline: none;
      @include max-screen($ipad-portrait){
        //display: flex;
      }
      @include max-screen($sm){
        top: 1550px;
      }
      .material-icons {
        margin-right: 23px;
        @include themify($themes) {
          color: themed('primaryColor');
        }
      }
    }
    &__list {
      position: relative;
      height: 0;
      overflow: hidden;
      width: calc(100% - 100px);
      margin-left: 50px;
      background-color: $gray;
      transition: height 1s ease-in;
      @include max-screen($ipad-portrait) {
        //border: 1px solid $red;
        box-shadow: 0px 0px 30px 0px rgba(218, 62, 22, 0.5);
        margin-bottom: 10px;
      }
      @include max-screen($sm) {
        width: calc(100% - 10px);
        margin-left: 5px;
      }
      &--visible {
        height: 1200px;
      }
      &-close {
        position: absolute;
        top: 0;
        right: 0;
        width: 54px;
        height: 54px;
        color: $orange;
        background-color: transparent;
        border: 0;
        outline: none;
        i {
          font-size: 36px;
        }
      }
      .team {
        &__country {
          position: static;
          text-align: center;
          padding-top: 65px;
          padding-bottom: 50px;
        }
      }
    }
    &__country {
      .member__list {
        @include max-screen($ipad-portrait) {
          display: none;
        }
      }
    }
  }
  .country {
    position: absolute;
    &--Ukraine {
      top: 620px;
      left: 1030px;
      @include max-screen($ipad-portrait){
        top: 520px;
        left: 600px;
      }
      @include max-screen($sm){
        top: 1300px;
        left: 30px;
      }
    }
    &--Denmark {
      top: 1000px;
      left: 500px;
      @include max-screen($desktop-md) {
        top: 880px;
        left: 480px;
      }
      @include max-screen($ipad-portrait){
        top: 620px;
        left: 300px;
      }
      @include max-screen($sm){
        top: 700px;
        left: 30px;
      }
    }
    &--USA {
      top: 200px;
      left: 950px;
      @include max-screen($desktop-md) {
        left: 750px;
      }
      @include max-screen($ipad-portrait){
        top: 50px;
        left: 500px;
      }
      @include max-screen($sm){
        top: 110px;
        left: 30px;
      }
    }
    &__name {
      font-size: 24px;
      padding-bottom: 5px;
      @include max-screen($md) {
        font-size: 18px;
      }
      @include max-screen($ipad-portrait) {
        font-size: 36px;
      }
      @include max-screen($sm) {
        font-size: 24px;
      }
    }
    &__description {
      @extend .subtitle;
      color: $white-06;
    }
  }

  .member {
    //display: none;
    position: absolute;
    width: 300px;
    height: 200px;
    z-index: 5;
    @include max-screen($notebook-HD) {
      width: 280px;
    }
    @include max-screen($notebook) {
      //height: 300px;
    }
    @include max-screen($md) {
      display: none;
    }
    @include max-screen($ipad-portrait) {
      position: static;
      display: block;
      width: 100%;
      height: 115px;
    }
    &--0 {
      top: 550px;
      left: 400px;
      @include max-screen($notebook-HD) {
        top: 535px;
        left: 390px;
      }
    }
    &--1 {
      display: none;
      left: 150px;
      bottom: 30px;
      @include max-screen($desktop-md) {
        left: 60px;
      }
      @include max-screen($notebook-HD) {
        left: 310px;
        bottom: 20px;
      }
      @include max-screen($ipad-portrait) {
        display: block;
      }

    }
    &--2 {
      display: none;
      left: 450px;
      top: 80px;
      @include max-screen($ipad-portrait) {
        display: block;
      }
    }
    &--3 {
      display: none;
      left: 450px;
      top: 80px;
      @include max-screen($ipad-portrait) {
        display: block;
      }
    }
    &--4 {
      display: none;
      left: 450px;
      top: 80px;
      @include max-screen($ipad-portrait) {
        display: block;
      }
    }
    &__content {
      position: relative;
      width: 100%;
      height: 100%;
      &.hover {
        border-top: 1px solid $red;
        border-right: 1px solid $red;
      }
    }
    &__elipse {
      display: none;
      position: absolute;
      right: 0;
      bottom: 0;
      width: 38px;
      height: 16px;
      transform: translate(50%, 50%);
      //transform: translate(50%, 50%) skew(0deg, -5deg);
      background-image: url("../../../img/team-elipse.png");
      background-repeat: no-repeat;
      background-size: contain;
      @include max-screen($ipad-portrait) {
        display: none;
      }
      &--hover {
        right: -1px;
        background-image: url("../../../img/team-elipse-hover.png");
        &+.member__info {
          display: flex;
        }
      }
    }
    &__info {
      display: none;
      box-shadow: 0px 0px 30px 0px rgba(218, 62, 22, 0.5);
      padding: 15px;
      background-color: $gray;
      transform: translate(-30px, -50%);
      @include max-screen($ipad-portrait){
        display: flex;
        transform: none;
        box-shadow: none;
        padding-left: 100px;
      }
      @include max-screen($sm) {
        padding-left: 15px;
      }
    }
    &__img {
      width: 70px;
      height: 70px;
      background-image: url("../../../img/team/member-default.png");
      background-size: contain;
      border-radius: 50%;
      @include max-screen($ipad-portrait) {
        width: 90px;
        height: 90px;
      }
      &--0 {}
    }
    &__description {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-left: 20px;
    }
    &__name {
      font-size: 18px;
      line-height: 28px;
      @include max-screen($ipad-portrait) {
        font-size: 24px;
      }
    }
    &__position {
      font-family: $Courier;
      font-size: 14px;
      @include max-screen($ipad-portrait) {
        font-size: 18px;
      }
    }
  }
}
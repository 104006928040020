.footer {
  &__menu {
    height: 320px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    @include max-screen($notebook-HD) {
      height: 250px;
    }
    @include max-screen($md) {
      height: 200px;
    }
    @include max-screen($sm) {
      height: auto;
      flex-direction: column;
      align-items: start;
      padding: 20px 0;
    }
  }
  &__link {
    padding: 0 20px;
    color: $white-08;
    text-decoration: none;
    @include max-screen($sm) {
      width: 100%;
      line-height: 40px;
    }
    &:hover {
      color: $white;
    }
  }
  &__copyright {
    @extend .subtitle;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $white-06;
    background-color: #050507;
    @include max-screen($sm) {
      flex-direction: column;
    }
  }
}